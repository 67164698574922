import axios from "axios";
import React, { useState } from "react";
import { ValidateForm } from "./ValidateForm";
import SignaturePad from "./SignaturePad";
import BackdropSpinner from "./BackdropSpinner";
import { apiUrl } from "./GlobalConfig";

const VAFormSpanish = () => {
  const [loading, setLoading] = useState(false);

  const [medicarenum, setMedicarenum] = useState("");

  const [signatureCleared, setSignatureCleared] = useState(false);

  const [errors, setErrors] = useState({});

  // Format the date as "YYYY-MM-DD" for the input field
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const handleSignatureEnd = (dataURL) => {
    //setSignature(dataURL);
    setFormData({ ...formData, signature: dataURL.split(",")[1] });
  };

  const [formData, setFormData] = useState({
    language: "SP",
    providerName: "",
    beneficiaryName: "",
    medicareNumber: "",
    printName: "",
    signedDate: formattedDate,
    signature: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, language: "SP", [name]: value });
  };

  const medicarenumberChange = (e) => {
    const value = e.target.value;
    if (value.includes(" ")) {
      const sanitizedValue = value.replace(/\s/g, "");
      setMedicarenum(sanitizedValue);
    } else {
      const sanitizedIn = value.replace(/[^a-zA-Z0-9]/g, "");
      setMedicarenum(sanitizedIn);
    }
    // const medistring = "PR" + e.target.value.toString();
    // const mbase64 = btoa(medistring);
    setFormData({
      ...formData,
      medicareNumber: e.target.value,
    });
  };

  const saveForm = async (request) => {
    setLoading(true);
    try {
      const response = await axios.post(apiUrl, request);

      // Handle the response from the API as needed
      console.log("Response from API:", response.data);
      setSignatureCleared(false);
      if (response.data === "OK") {
        alert(response.data);
      } else {
        alert(response.data);
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error:", error);
      alert(" From Submission Failed!");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Include logic to submit the form data along with the signature.
    console.log("Form Data:", { formData });
    const validationErrors = ValidateForm(formData);

    console.log(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setSignatureCleared(true);
      // Form is valid, submit the data
      saveForm(formData);
      setFormData({
        providerName: "",
        beneficiaryName: "",
        medicareNumber: "",
        printName: "",
        signedDate: formattedDate,
        signature: "",
      });
      setMedicarenum("");
    } else {
      // Form is invalid, set validation errors
      setErrors(validationErrors);
    }
  };
  return (
    <>
      {loading && <BackdropSpinner show={loading} />}

      <div
        className="mt-3 col-md-7 mx-auto"
        style={{ maxWidth: 1500, padding: "2em", border: "solid" }}
      >
        <img src="./CCMDatHome.png" alt="CCMD logo" className="p-3" />
        <img
          src="./CMS_logo.png"
          alt="CMS logo"
          style={{ height: 70, marginLeft: "20em" }}
        />

        <h4 className="mt-3">
          Formulario de Confirmación del Medico Principal u otro Profesional de
          la Salud
        </h4>

        <div
          className="border border-dark"
          style={{ margin: "12px -10px", padding: "8px 24px" }}
        >
          <h3 className="vaFont vaFont24B">1.Confirmar</h3>
          <p className="vaFont vaFont17">
            Al flrmar a continuación, conflrmo que mi medico de cabecera u otro
            profesional de la salud, o el lugar principal al que voy para
            recibir atención medica de rutina, es:
          </p>
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 my-1"
                style={{
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    flexBasis: "50%",
                    fontFamily: "Lato",
                    fontSize: "17px",
                  }}
                >
                  Nombre del Proveedor y/o grupo medico
                </h6>
              </div>
              <div className="col-md-6 my-1">
                <input
                  type="text"
                  className="ms-3 p-2 w-100"
                  maxLength={30}
                  autoComplete="on"
                  id="ProviderName"
                  name="providerName"
                  value={formData.providerName}
                  onChange={handleChange}
                />
                {errors.providerName && (
                  <span className="ms-3" style={{ color: "red" }}>
                    {errors.providerName}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-1" style={{ alignItems: "center" }}>
                <h6
                  style={{
                    flexBasis: "50%",
                    fontFamily: "Lato",
                    fontSize: "17px",
                  }}
                >
                  Nombre del beneficiaro
                </h6>
              </div>
              <div className="col-md-6 my-1">
                <input
                  type="text"
                  className="ms-3 p-2 w-100"
                  autoComplete="on"
                  maxLength={30}
                  id="BeneficiaryName"
                  name="beneficiaryName"
                  value={formData.beneficiaryName}
                  onChange={handleChange}
                />
                {errors.beneficiaryName && (
                  <span className="ms-3" style={{ color: "red" }}>
                    {errors.beneficiaryName}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex mt-3">
            <div style={{ flexBasis: "65%" }}>
              <SignaturePad
                onEnd={handleSignatureEnd}
                onClear={signatureCleared}
              />
            </div>
            <div className="ps-2 align-self-end" style={{ flexBasis: "35%" }}>
              <input
                type="date"
                readOnly
                className="w-100 p-2"
                id="SignedDate"
                name="signedDate"
                value={formData.signedDate}
                onChange={handleChange}
              />
              <h6 className="vaFont vaFont17">
                Fecha <span style={{ color: "red" }}>(Requerida)</span>
              </h6>
            </div>
          </div>
          <div className="col-md-7">
            <h6 className="vaFont vaFont17">Firma </h6>
            <input
              type="text"
              className="w-100 p-2"
              autoComplete="on"
              maxLength={35}
              id="PrintName"
              name="printName"
              value={formData.printName}
              onChange={handleChange}
            />

            <h6 className="vaFont vaFont17">
              Nombre impreso del firmante{" "}
              {errors.printName && (
                <span className="ms-3" style={{ color: "red" }}>
                  {errors.printName}
                </span>
              )}
            </h6>
            <input
              type="text"
              className="w-100 p-2"
              maxLength={11}
              id="medicareNumber"
              name="medicareNumber"
              value={medicarenum}
              onChange={medicarenumberChange}
            />

            <h6 className="vaFont vaFont17">
              Numero de Medicare{" "}
              {errors.medicareNumber && (
                <span className="ms-3" style={{ color: "red" }}>
                  {errors.medicareNumber}
                </span>
              )}
            </h6>
          </div>

          <p className="vaFont vaFont17 lh-sm mt-3">
            Nota: Si los nombres enumerados anteriormente yen la carta adjunta
            son incorrectos, no firme este formulario. Si desea recibir un nuevo
            formulario con un medico diferente, otro profesional de la salud o
            consultorio, o para optar porno recibir avisos futures, Ilame a
            CareConnectMD at Home al 1-888-874-0818, TTY 711.
          </p>

          <button className="btn btn-success mx-1" onClick={handleSubmit}>
            Submit
          </button>
        </div>

        <h3 className="vaFont vaFont24B">2.Devolver</h3>
        <p className="vaFont vaFont17 lh-sm" style={{ fontWeight: 600 }}>
          Devuelva este formulario a nuestra practica o envielo por correo a:
        </p>
        <div className="vaFont vaFont17 d-flex">
          <p style={{ flexBasis: "50%" }}>
            CareConnectMD <br />
            3090 Bristol St Ste 200
            <br /> Costa Mesa, CA 92626
            <br /> FAX : 877-738-0868
          </p>
        </div>
        <p className="vaFont vaFont17 lh-sm mt-3" style={{ fontWeight: 600 }}>
          Nota: Completar y devolver este formulario es voluntario. No afectara
          sus beneflcios de Medicare. Este formulario no es valido a menos que
          incluya tanto una flrma como una fecha.
        </p>
      </div>
    </>
  );
};

export default VAFormSpanish;
